.p-checkbox .p-checkbox-box {
    background: $color-light-grey;
    border: none;
    width: 24px;
    height: 24px;
}
p-checkbox:not(.p-checkbox-disabled)
.p-checkbox-box
.p-highlight:hover {
    border: none;
    background: $color-blue !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
    background: $color-blue !important;

}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    box-shadow: none !important;
}

.p-checkbox-label {
    font-family: $font-family-IBM-Plex-Sans;
    font-size: 16px;
    color: $color-dark-grey;
}
