/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//@import "../../projects/ftable-components/assets/scss/settings/variables";
@import "./variables";
@import "ftable/ftable-main";
@import "~primeicons/primeicons.css";
@import "~primeng/resources/themes/lara-light-indigo/theme.css";
@import "~primeng/resources/primeng.min.css";
@import "./settings/spacing-units";
@import "./ionic-component";
@import "./primeng-component";

html {
    background: #f2f3f5;
    font: $font-family-IBM-Plex-Sans;
}

body {
    padding: 0;
    //margin-top: constant(safe-area-inset-top);
    //margin-top: env(safe-area-inset-top);
    background: #f2f3f5 !important;
}

.outer-container {
    position: relative;
    max-width: 800px;
    height: auto;
    margin: 0 auto;
    background: #f2f3f5;
}

#background-content {
    display: none !important;
}

.u-container {
    padding-left: 16px;
    padding-right: 16px;
}

.footer {
    border-top: 1px solid #e6e7eb;
    padding: 16px;
    background: #f7f7f7;
}

.toolbar-background {
    display: none !important;
}

.validation-errors {
    font-size: 14px;
    line-height: 20px;
    color: #eb3838;
    margin: 8px 0 4px 8px;
}

.distance {
    font-weight: bold;
    color: #ff6d03;
}

.own-location {
    cursor: pointer;
}

.version {
    text-align: center;
    font-size: 11px;
    color: #8a8f99;
}
.update-popup {
    color: var(--color-input);
}

.upgrade-info {
    width: 100%;
    height: 80vh;
}

.pi-getcoords {
    cursor: pointer;
}

.list {
    padding-bottom: 30px;
}

.input-item {
    display: block;
    margin: 0.5rem 0;

    @media (min-width: 370px) {
        display: flex;
    }

    > label {
        display: block;
        width: 100%;

        @media (min-width: 370px) {
            width: 25%;
        }
    }

    > div {
        width: 100%;

        @media (min-width: 370px) {
            width: 75%;

        }
    }
}

.radio-group {
    .radio-item {
        display: inline-block;
        padding: 6px 16px;
    }
}
.search-container {
    position: relative;
    width: 100%;
    margin-bottom: 10px;

    .btn-round {
        position: absolute;
        top: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #e6e7eb;
        border: solid 1px transparent;

        &:hover {
            background: #efeff1;
        }
    }

    .search-icon {
        position: absolute;
        top: 5px;
        left: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn-geolocate {
        right: 5px;
        width: 30px;
        color: #005470;
    }

    .pi {
        color: #005470;
    }

    input {
        border-radius: $border-radius;
        border: none;
        background-color: #e6e7eb;
        color: $color-dark-grey;
        font-size: 14px;
        font-family: $font-family-IBM-Plex-Sans;
        font-weight: $font-weight-regular;
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        min-width: max-content;
        padding: 11px 40px;

        &:focus {
            box-shadow: none;
            border: none;
            outline: none;
        }
    }

    &__skeleton {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 40px;
        z-index: 2;
    }

    &__skeleton ::ng-deep div {
        border-radius: $border-radius;
    }
}


.search-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 4px;
    font-size: 11px;
    color: var(--color-label);
    //text-align: center;
}

.modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    height: 100%;

    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border-radius: 40px;
        background: var(--color-blue);
        border: solid 6px white;
        box-shadow: 0 0 1px 0 rgba(41, 44, 51, 0.32), 0 1px 1px 0 rgba(41, 44, 51, 0.04), 0 2px 2px 0 rgba(41, 44, 51, 0.04), 0 4px 4px 0 rgba(41, 44, 51, 0.04), 0 8px 8px 0 rgba(41, 44, 51, 0.04);

        ion-icon {
            width: 40px;
            height: 40px;
        }
    }

    &__content {
        h1 {
            color: #292c33;
        }

        p {
            color: #8a8f99;
        }
    }

    &__button {

    }
}
