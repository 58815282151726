ion-title {
    text-align: center;
}

ion-content {
    --ion-background-color: #f2f3f5;

    ion-button {
        &[expand='block'] {
            text-transform: unset;
        }
    }
}

ion-button {
    &[expand='block'] {
        --border-radius: 24px;
    }
}

ion-footer {
    position: absolute;
    bottom: 0;

    ion-toolbar {
        padding: 8px 16px;
    }
    ion-button[expand='block'] {
        margin: 16px;
    }
}

ion-toolbar {
    --background: #fff;
}

ion-modal {
    --max-height: 90%;
    --width: 96%;
    --border-radius: 20px;
    --border-style: solid;
    --border-color: #005470;
}

ion-icon {
    width: 20px;
    height: 20px;
}
