.p-slider.p-slider-horizontal {
    height: 2px;
}

.p-slider .p-slider-handle {
    box-shadow: 0 0 1px 0 rgba(41, 48, 51, 0.32), 0 1px 1px 0 rgba(41, 48, 51, 0.08), 0 2px 2px 0 rgba(41, 48, 51, 0.08), 0 4px 4px 0 rgba(41, 48, 51, 0.08), 0 8px 8px 0 rgba(41, 48, 51, 0.08);
    background-color: #fff;
    border: none;
}

.p-slider .p-slider-handle:focus {
    box-shadow: 0 0 1px 0 rgba(41, 48, 51, 0.32), 0 1px 1px 0 rgba(41, 48, 51, 0.08), 0 2px 2px 0 rgba(41, 48, 51, 0.08), 0 4px 4px 0 rgba(41, 48, 51, 0.08), 0 8px 8px 0 rgba(41, 48, 51, 0.08);
}

.p-slider .p-slider-range {
    background: $color-blue;
}
