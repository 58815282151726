// FreeTable Variables and Theming

/** Fonts **/

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,500;0,600;1,100&display=swap');
$font-family-IBM-Plex-Sans: 'IBM Plex Sans', sans-serif;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semiBold: 600;
$font-weight-thin: 100;


/** colors **/

$color-orange: #ff6d03;
$color-blue: #005470;
$color-green: #1cad34;
$color-red: #eb3838;
$color-light-grey: #f2f3f5;
$color-medium-light-grey: #e6e7eb;
$color-medium-grey: #8a8f99;
$color-dark-grey: #292c33;
$color-white: #ffffff;

/** border-radius **/

$border-radius: 24px;
$border-radius-images: 12px;

/** box-shadow **/
$box-shadow-light: 0 0 1px 0 rgba(41, 44, 51, 0.32), 0 1px 1px 0 rgba(41, 44, 51, 0.04), 0 2px 2px 0 rgba(41, 44, 51, 0.04), 0 4px 4px 0 rgba(41, 44, 51, 0.04), 0 8px 8px 0 rgba(41, 44, 51, 0.04);
$box-shadow-dark: 0 0 1px 0 rgba(41, 48, 51, 0.32), 0 1px 1px 0 rgba(41, 48, 51, 0.08), 0 2px 2px 0 rgba(41, 48, 51, 0.08), 0 4px 4px 0 rgba(41, 48, 51, 0.08), 0 8px 8px 0 rgba(41, 48, 51, 0.08);
