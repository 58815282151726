.a-label {
    font-family: $font-family-IBM-Plex-Sans;
    font-weight: $font-weight-medium;
    font-size: 14px;
    color: $color-medium-grey;
    display: inline-flex;
    align-items: center;

    svg {
        margin-right: 4px;
    }

    &--isLarge {
        font-size: 18px;
    }

    &--dark {
        color: $color-dark-grey;
    }

    &--grey {
        color: $color-medium-grey;
    }

    &--white {
        color: $color-white;
    }

    &--red {
        color: $color-red;
    }

    &--blue {
        color: $color-blue;
    }

    &--isThin {
        font-weight: $font-weight-thin;
    }

    &--hasBackground {
        background-color: $color-medium-grey;
    }
}
