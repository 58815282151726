.p-toast {
    opacity: 1;

    .p-toast-message {
        &.p-toast-message-info {
            background-color: white;
            border-width: 1px;
            border-color: var(--color-blue);
            color: var(--color-blue);

            .p-toast-icon-close {
                color: var(--color-blue);
            }
        }

        &.p-toast-message-error {
            background-color: white;
            border-width: 1px;
            border-color: var(--color-red);
            color: var(--color-red);

            .p-toast-icon-close {
                color: var(--color-red);
            }
        }
    }
}
