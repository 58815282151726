.o-card {
    background: white;
    padding: 16px;
    border-radius: $border-radius;
    box-shadow: $box-shadow-light;

    &--dark {
        box-shadow: $box-shadow-dark;
    }

    &--top-border-only {
        border-radius: $border-radius $border-radius 0 0;
        box-shadow: none;
    }

    &--no-radius {
        border-radius: 0;
    }
}

