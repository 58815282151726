.p-radiobutton .p-radiobutton-box {
    background: #e6e7eb;
    border: none;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: $color-blue;
    background: $color-blue;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: none;
}
.p-radiobutton-label {
    font-family: $font-family-IBM-Plex-Sans;
    font-size: 16px;
    color: $color-dark-grey;
}
